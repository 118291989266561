import React from "react"

import { Banner, Section } from "../components/elements"
import styled from "styled-components"
import { Col, Color, Media, Wrapper } from "../components/utilities"

import { Layout } from "../components/layouts/Layout"

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  ${Media.below.tablet`
    text-align: center;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}
`

const OrangeBar = styled.div`
  height: 3px;
  width: 40px;
  background-color: ${Color.orange};
  margin-bottom: 1rem;

  ${Media.below.tablet`
    display: none;
  `}
`

const PracticeAreas = () => {
  return (
    <Layout title="Practice Areas">
      <Banner title="Practice Areas" />
      <Section>
        <Wrapper>
          <SpecialGrid>
            <Col>
              <h3>Estate Planning</h3>
              <OrangeBar />
              <p>
                The attorneys at Pfost Law, Ltd. have years of experience
                working with individuals and families to provide customized,
                thorough and confidential estate planning services. We carefully
                evaluate a client’s circumstances and use a combination of
                wills, trusts, powers of attorney, health care directives and
                advanced estate planning tools, to provide clients with the most
                effective methods for passing assets to loved ones. For our
                business owners, we are careful to ensure that your estate plan
                coordinates with your business succession plan.
              </p>
            </Col>
            <Col>
              <h3>Family Law</h3>
              <OrangeBar />
              <p>
                We are experienced in representing individuals through the
                emotional and legal challenges associated with all family law
                matters. Family law includes divorce, dissolution, annulment,
                legal separation, and child custody matters in both domestic
                relations court and juvenile court. Every case is different and
                requires individual attention. We have represented individuals
                in cases that involve: child support, child custody, spousal
                support, tax exemptions, real estate, businesses and personal
                property. We are with you throughout the process, whether its
                mediation, negotiation, or litigation.
              </p>
            </Col>
            <Col>
              <h3>Probate and Trust Administration</h3>
              <OrangeBar />
              <p>
                We understand the loss of a loved one is a difficult time. The
                attorneys at Pfost Law, Ltd. take great care to assess a
                family’s needs and guide them through probate and trust
                administrations with compassion. We utilize our years of
                experience to transfer assets to intended beneficiaries in the
                most efficient and tax sensitive manner possible. Whether your
                loved one passed away with a will, without a will, or with more
                advanced planning, we are here to help.
              </p>
            </Col>
            <Col>
              <h3>Real Estate</h3>
              <OrangeBar />
              <p>
                We are experienced in representing clients in a broad range of
                residential, commercial, and farm real estate transactions.
                Whether you are buying or selling a home, purchasing or leasing
                business property, or considering a 1031 Exchange for farm or
                other commercial property, we would welcome the opportunity to
                represent you.
              </p>
            </Col>
            <Col>
              <h3>Business Law</h3>
              <OrangeBar />
              <p>
                The attorneys at Pfost Law, Ltd. are experienced in representing small closely held
                businesses. We can assist you with starting a new
                business, business succession
                planning, and corporate governance.
              </p>
            </Col>
          </SpecialGrid>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default PracticeAreas
